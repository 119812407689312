/** @jsx h **/
import { component, h, VElement, When } from "alfama";
import { createMentionsPlugin, ListOption, MentionsPlugin } from "./utils";

export const MessageComposer = component<{
  initialValue: string;
  children: VElement;
  onSubmit: (text: string) => void;
  onInput: (text: string) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
}>(
  "MessageComposer",
  (props, { signal, store, onMount, getContext, onUnmount, wire }) => {
    const $mod = signal<typeof import("@gratico/uikit") | undefined>(
      "mod",
      undefined
    );
    onMount(async () => {
      const mod = await import("@gratico/uikit");
      $mod.set(mod);
    });
    return (
      <When
        condition={($) => !!$mod.get($)}
        views={{
          true: () => {
            const { RichComposer, setupRichEditor } =
              $mod.get() as typeof import("@gratico/uikit");
            return (
              <div
                style="background: var(--component-bg-color);"
                class="relative flex  flex-col px-2"
                ref={(el) => {
                  setupRichEditor(el, {
                    onSubmit: (text: string) => {
                      props.onSubmit(text);
                    },
                    onInput: (text: string) => {
                      props.onInput(text);
                    },
                  });
                }}
              >
                <div class=" hidden flex items-center px-2 py-1 border-b ">
                  <button class="rounded pr-2 text-1xl ">
                    <i class="ri-image-line"></i>
                  </button>
                  {false && (
                    <button class="rounded ">
                      <i class="ri-slash-commands-2"></i>{" "}
                    </button>
                  )}
                </div>
              </div>
            );
          },
          false: () => null,
        }}
      ></When>
    );
  }
);
