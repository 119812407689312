import { defineContext, component, VElement, Signal } from "alfama";
import {
  IMasterKernel,
  IViewportKernel,
  IViewport,
  IMasterKernelAPI,
} from "@gratico/kernel";

export type KernelConnection = {
  kernel: IViewportKernel;
  master: IMasterKernelAPI;
};

export const KernelContext =
  defineContext<Signal<KernelConnection>>("KernelContext");

export const KernelProvider = component<{
  children: VElement;
  kernel: IViewportKernel;
  master: IMasterKernelAPI;
}>("KernelConnection", (props, { setContext, signal }) => {
  const $kernelConnection = signal<KernelConnection>("kernelConnection", {
    kernel: props.kernel,
    master: props.master,
  });
  setContext(KernelContext, $kernelConnection);
  return props.children;
});
