/** @jsx h **/

import { component, h, produce, When } from "alfama";

import { Welcome } from "../../../../hub/src/components/Welcome/index";
import { BuffersContext } from "../../providers/index";
import { KernelContext } from "../../providers/index";
import { Link } from "alfama-router";

import { WorkspaceGraph } from "../WorkspaceGraph";

export const Home = component("Home", (props, { getContext, onMount }) => {
  const $buffers = getContext(BuffersContext);
  onMount(() => {
    produce($buffers, (s) => (s.activeBufferId = undefined));
  });
  onMount(() => {
    // icon
    const icon = document
      .querySelector(`head link[rel="apple-touch-icon"]`)
      ?.getAttribute("href");

    if (icon)
      document
        .querySelector(`head link[rel="icon"]`)!
        .setAttribute("href", icon);
  });
  // return <WorkspaceGraph />;
  return (
    <div
      style="width: 100vw; height: 100vh; padding: 70px 0;"
      class="flex flex-col overflow-y-auto"
    >
      <div style="" class="max-w-3xl mx-auto  ">
        <div class="py-4">
          <GetStartedButton />
        </div>
        <div class="flex justify-center items-center">
          <Welcome />
        </div>
      </div>
    </div>
  );
});

export const GetStartedButton = component(
  "GetStartedButton",
  (props, { signal, getContext, onMount }) => {
    const { kernel } = getContext(KernelContext).get();
    const $loaded = signal<false | string>("loaded", false);
    onMount(async () => {
      $loaded.set(kernel.params.session.project.id);
    });
    return (
      <Link
        class="button text-4xl block flex justify-center items-center py-3 primary"
        href={`~/alfama/${kernel.params.session.project.id}/master/~/main.space`}
      >
        <i class="ri-arrow-right-circle-fill"></i>
        <span>Check it out </span>
      </Link>
    );
  }
);
