/** @jsx h **/

import {
  IBufferRouteParams,
  getBufferIdFromRouterParams,
  getFileIcon,
} from "@gratico/kernel";
import { component, produce, reify } from "alfama";
import { ParentRouteContext } from "alfama-router";

import { KernelContext } from "../../providers/index";

import { basename } from "pathe";
import { BuffersContext } from "../../providers/index";
import { setTitle } from "../../utils";

export const BufferRoute = component(
  "Empty",
  (props, { getContext, onMount, wire }) => {
    const { kernel, master } = getContext(KernelContext).get();
    const route = getContext(ParentRouteContext).get();
    const $buffers = getContext(BuffersContext);
    onMount(async () => {
      if (!route) return;
      // todo: look into how to make path parsing typesafe the way hono does
      // important: this isn't typesafe
      const bufferParams = {
        ...route.params,
        path: "/" + route.params.path,
      } as IBufferRouteParams;
      const bufferId = getBufferIdFromRouterParams(bufferParams);

      const existing = reify($buffers.list).find((el) => el.id === bufferId);
      // console.log("existing", existing);
      if (existing) {
        produce($buffers, (s) => {
          s.activeBufferId = bufferId;
        });
      } else {
        try {
          const icon = await getFileIcon(
            kernel,
            {
              path: bufferParams.path,
            },
            {}
          );
          const tempBuffer = {
            ...bufferParams,
            ref: bufferParams.ref,
            id: bufferId,
            icon,
            createdAt: Date.now(),
          };
          // console.log("tempBuffer", tempBuffer);
          produce($buffers, (s) => {
            s.list.push(tempBuffer);
            s.activeBufferId = tempBuffer.id;
          });
        } catch (e) {
          // if fails due to git conflict in lock file
          console.error(e);
        }
      }
    });
    wire(async ($) => {
      const buffers = $($buffers);
      const activeBuffer =
        buffers.activeBufferId &&
        buffers.list.find((el) => el.id === buffers.activeBufferId);
      if (activeBuffer) {
        const path = activeBuffer.path;
        const baseName = basename(path);
        setTitle(`${baseName}`);
        const icon = await getFileIcon(kernel, { path: path } as any, {
          //        whiteboard: whiteboardImageURL,
        });
        document
          .querySelector(`head link[rel="icon"]`)!
          .setAttribute("href", icon);
      }
    }).run();
    return null;
  }
);
