/** @jsx h **/

import {
  bots,
  createAssistantMessage,
  createUserMessage,
} from "@gratico/kernel";
import { IBotContext, IChatMessage } from "@gratico/sdk";
import {
  component,
  Each,
  Fragment,
  h,
  produce,
  reify,
  Signal,
  When,
} from "alfama";
import * as smd from "streaming-markdown";
import { MessageComposer } from "../../components/MessageComposer";
import { LiveMessage } from "./components/LiveMessage";
import { ChatMessage } from "./components/Message";
import { runAndProcessToolCall } from "./utils";

export const ChatInner = component<{ $expanded: Signal<boolean> }>(
  "ChatInner",
  (props, { signal, store, wire, onMount, getContext }) => {
    const $messages = store<{ list: IChatMessage[] }>("messsages", {
      list: [],
    });
    const $messageText = signal<string>("message", "");
    const $loading = signal("loading", false);
    let $$scrollContainer: HTMLDivElement | undefined;
    const handleMessageSubmit = async () => {
      $loading.set(true);
      const context: IBotContext = {} as any;
      const rawPrompt = $messageText.get();
      console.log("bots", bots);
      const m = await createUserMessage("", bots, rawPrompt, context);
      console.log("m", m);
      produce($messages, (s) => {
        s.list.push(m);
      });
      setTimeout(() => {
        // https://stackoverflow.com/a/270628
        $$scrollContainer
          ? ($$scrollContainer.scrollTop = $$scrollContainer.scrollHeight)
          : null;
      }, 0);
      $messageText.set("");
      const $$liveEl = document.querySelector("#gratico-chat-liveEL");

      const renderer = smd.default_renderer($$liveEl as HTMLElement);
      const parser = smd.parser(renderer);
      const response: any = await runAndProcessToolCall([m], [], {
        // "gpt-4o-mini", //
        model: "@cf/meta/llama-3.1-8b-instruct",
        onToken: (token) => {
          const atBottom =
            $$scrollContainer &&
            $$scrollContainer.scrollTop + $$scrollContainer.offsetHeight + 5 >
              $$scrollContainer.scrollHeight;
          smd.parser_write(parser, token);

          //        console.log($$liveEl, atBottom);
          if (atBottom) {
            setTimeout(() => {
              //$$liveEl && $$liveEl.scrollIntoView(false);
              $$scrollContainer
                ? ($$scrollContainer.scrollTop = $$scrollContainer.scrollHeight)
                : null;
            }, 0);
          }
        },
        onComplete: async (text) => {
          console.log("message complete", m);
          const assistantMeasge = await createAssistantMessage(
            bots,
            text,
            context
          );
          produce($messages, (s) => {
            s.list.push(assistantMeasge);
          });
          $loading.set(false);
        },
      });
      console.log("response", response);
    };

    onMount(() => {});

    return (
      <div class="flex-1 flex h-full">
        <div class="flex-1 flex flex-col  relative h-full ">
          <div class="p-4 border-b">
            <h1 class="text-xl font-semibold logo-gradient">
              <i class="ri-sparkling-2-line"></i> AI
            </h1>
            <p class="text-sm ">Handling Messages</p>
          </div>

          <div
            style="padding-bottom: 0px; background: var(--component-bg-color); border-bottom: 1px solid var(--component-border-color); "
            class="flex flex-1 flex-col overflow-hidden"
          >
            <div
              ref={(el) => ($$scrollContainer = el)}
              class="flex-1 overflow-y-auto p-2 space-y-0"
            >
              <Each
                cursor={$messages.list}
                renderItem={(cursor, index) => {
                  const message = reify(cursor());
                  return <ChatMessage key={index} message={message} />;
                }}
              />
              <When
                condition={($) => $loading.get($)}
                views={{
                  true: () => {
                    return <LiveMessage />;
                  },
                  false: () => null,
                }}
              />
            </div>
          </div>

          <div style="" class=" relative absolute  bottom-0 right-0 left-0">
            <div style="" class="">
              <MessageComposer
                onKeyDown={(e) => {
                  if (e.key == "Escape") {
                    props.$expanded.set(false);
                  }
                }}
                initialValue={$messageText.get()}
                onInput={(text) => {
                  $messageText.set(text);
                  //console.log("setting", text, $messageText.get());
                }}
                onSubmit={async (text) => {
                  $messageText.set(text);
                  if ($messageText.get().trim().length === 0) return;
                  handleMessageSubmit();
                }}
              >
                <Fragment></Fragment>
              </MessageComposer>
              <div
                style=" min-height: 35px;"
                class=" px-2 py-0 flex items-center border-t"
              >
                {wire(($) => {
                  const message = $messageText.get($);
                  const bots = message.match(/#([a-zA-Z]*)/gi);
                  //console.log("bots", message, bots);
                  if (bots && bots.length > 0) {
                    const bot = bots[0].slice(1);
                    if (bot) {
                      //console.log("returning", bot);
                      return bot;
                    }
                  }
                  return "";
                })}
              </div>
            </div>
          </div>
        </div>

        <div class="w-64  p-4 border-l">
          <h2 class="font-semibold mb-4">Chat Controls</h2>
          <div class="space-y-4">
            <div>
              <h3 class="text-sm font-medium ">Model</h3>
              <p class="text-sm">Chat Assistant 1.0</p>
            </div>
            <div>
              <h3 class="text-sm font-medium ">Artifacts</h3>
              <div class=" p-2 rounded border mt-1">
                <p class="text-sm">No artifacts yet</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
