/** @jsx h **/

import { component, h, reify, StoreCursor } from "alfama";

import { FileProperties } from "@gratico/shell/src/components/FileProperties";
import { ModalsAPIContext } from "@gratico/shell/src/providers/Modals";
import pathe from "pathe";
import { BanyanProps, TreeNode } from "../../types";
import styles from "./TreeItemActions.module.less";
export { IModeTypes } from "../../utils/index";

export const TreeItemActions = component<
  BanyanProps & {
    cursor: () => StoreCursor<TreeNode>;
  }
>("TreeItemActions", (props, { wire, getContext, onMount, signal }) => {
  const item = reify(props.cursor());
  const modalsAPI = getContext(ModalsAPIContext);
  return (
    <span data-actions class={styles.itemActions} key="actions">
      <a
        onClick={() => {
          modalsAPI.showModel(
            <FileProperties
              parentFolder={pathe.dirname(item.path)}
              nodeName={item.name}
              onClose={() => modalsAPI.closeModal()}
            />
          );
        }}
        style={item.isDirectory ? `` : `display: none;`}
        key="add"
        href="#"
      >
        <i class="ri-add-circle-line"></i>
      </a>
    </span>
  );
});
