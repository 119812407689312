/** @jsx h **/

import {
  ILoggedinSession,
  IMasterKernelAPI,
  IViewportKernel,
} from "@gratico/sdk";
import { useSliding } from "@gratico/uikit";
import { component, h, Signal } from "alfama";
import { CheckoutTree } from "./CheckoutTree";

export const NavIsland = component<{
  master: IMasterKernelAPI;
  kernel: IViewportKernel;
  session: ILoggedinSession;
  $activeRef: Signal<string | undefined>;
}>("NavIsland", (props, utils) => {
  const { $translateX } = useSliding(
    utils,
    ($expanded, e: MouseEvent) => {
      if ($expanded.get() == false && e.clientX < 40 && e.clientY > 80) {
        $expanded.set(true);
      } else if ($expanded.get() == true && e.clientX < 290 && e.clientY > 80) {
        $expanded.set(true);
      } else {
        $expanded.set(false);
      }
    },
    0,
    -90
  );
  const { wire, signal, onMount, onUnmount } = utils;
  return (
    <div
      style={wire(
        ($) =>
          `top: 60px; bottom: 60px; width: 290px; left: 10px; transform: translateX(${$translateX.get(
            $
          )}%); z-index: 21474836;`
      )}
      class={
        "flex flex-col layered-bg absolute  left-0  border rounded  overflow-y-auto"
      }
    >
      <div class="pb-2">
        <CheckoutTree {...props} refName={"master"} />
      </div>
    </div>
  );
});
