/** @jsx h **/

import {
  defineContext,
  component,
  VElement,
  StoreCursor,
  createStore,
  Fragment,
  h,
  When,
} from "alfama";
import styles from "./Modals.module.less";

export type ModalsAPI = {
  showModel(el: VElement): void;
  closeModal(): void;
};
export const ModalsAPIContext = defineContext<ModalsAPI>("ModalsAPI");

export const ModalsProviders = component<{
  children: VElement;
}>("ModalsProviders", (props, { setContext, signal, store }) => {
  const $modal = signal<VElement | undefined>("modal", undefined);
  const modalsAPI: ModalsAPI = {
    showModel(el: VElement) {
      //console.log("showmodal", el);
      $modal.set(el);
    },
    closeModal() {
      $modal.set(undefined);
    },
  };
  setContext(ModalsAPIContext, modalsAPI);
  return (
    <Fragment>
      {props.children}
      <When
        condition={($) => !!$modal.get($)}
        views={{
          true: () => {
            return (
              <Fragment>
                <dialog
                  ref={(el) => {
                    setTimeout(() => el.showModal(), 0);
                    const handler = () => {
                      console.log("closed");
                      el.removeEventListener("close", handler);
                      $modal.set(null);
                    };
                    el.addEventListener("close", handler);
                  }}
                  style="z-index: 2147483647; overflow: hidden;"
                  id="my_modal_1"
                  class="modal "
                >
                  {$modal.get()}
                </dialog>
              </Fragment>
            );
          },
          false: () => {
            return null;
          },
        }}
      />
    </Fragment>
  );
});
