/** @jsx h **/
import { h, component, Fragment, render as alfamaRender } from "alfama";
import autocomplete from "autocompleter";
import {
  IBufferRouteParams,
  IMasterKernelAPI,
  IViewportKernel,
} from "@gratico/sdk";
import { extractParameters, RouterContext, RouterObject } from "alfama-router";
import { BufferRoutePattern } from "../../constants";
import pathe from "pathe";

const setupAutocomplete = (
  container: HTMLDivElement,
  master: IMasterKernelAPI,
  kernel: IViewportKernel,
  router: RouterObject,
  onClose: Function
) => {
  const input = container.querySelector("input") as HTMLInputElement;
  const autocompleteContainer = container.querySelector(
    `[data-autocomplete-container]`
  ) as HTMLDivElement;

  const $loading = container.querySelector(".loading") as HTMLElement;
  autocomplete<{
    id: string;
    label: string;
    meta: { name: string; dir: string; path: string };
  }>({
    input: input,
    minLength: 0,
    showOnFocus: true,
    container: autocompleteContainer,
    customize(input, inputRect, container, maxHeight) {
      container.style.width = 530 + "px";
      container.style.top = 60 + "px";
      container.style.left = 10 + "px";
    },
    render(item, currentValue, index) {
      const itemElement = document.createElement("div");
      itemElement.innerHTML = `<div>
          <div>${item.meta.name}</div>
          <div class="text-xs truncate " data-tip="${item.meta.dir}" >${item.meta.dir}</div>
        </div>`;
      return itemElement;
    },
    fetch: async function (query, update) {
      query = query.toLowerCase();
      const params = extractParameters<Partial<IBufferRouteParams>>(
        BufferRoutePattern,
        window.location.pathname
      ) || { projectId: kernel.params.session.project.id, ref: "master" };

      if (params && params.projectId && params.ref) {
        $loading.style.visibility = "visible";
        const results = await master.searchNodes(
          params.projectId,
          params.ref,
          query
        );
        // you can also use AJAX requests instead of preloaded data
        var suggestions = results.map((el) => ({
          id: el.path,
          label: el.name,
          meta: el,
        }));
        update(suggestions);
        $loading.style.visibility = "hidden";
      }
    },
    onSelect: function (item) {
      const path = item.id as string;
      input.value = path;
      const ext = pathe.extname(path as string);
      const params = extractParameters<Partial<IBufferRouteParams>>(
        BufferRoutePattern,
        window.location.pathname
      ) || { projectId: kernel.params.session.project.id, ref: "master" };
      if (params && params.projectId && params.ref) {
        const href = `/~/${ext === ".space" ? "alfama" : "yjs"}/${
          params.projectId
        }/${params.ref}/~${path as string}`;
        router.navigate(href);
        onClose();
      }
    },
  });
  setTimeout(() => {
    input.focus();
  }, 0);
};

export const CommandBar = component<{
  onClose: Function;
  master: IMasterKernelAPI;
  kernel: IViewportKernel;
}>("CommandBar", (props, { onMount, getContext }) => {
  const router = getContext(RouterContext).get();
  return (
    <Fragment>
      <div
        style="position: absolute; left: 50%; top: 120px; width: 550px; z-index: 2147483647; transform: translate(-50%); "
        class="absolute flex flex-col rounded"
      >
        <div
          class="form-control"
          ref={(el) =>
            setupAutocomplete(
              el,
              props.master,
              props.kernel,
              router,
              props.onClose
            )
          }
        >
          <label class="">
            <input
              class="w-full px-4 py-4"
              autofocus={true}
              name="name"
              type="text"
              pattern=".{1,}"
              placeholder={"Search project..."}
              required
              autocomplete={"off"}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  props.onClose();
                }
              }}
              autoFocus={true}
            />
            <span
              style="visibility: hidden; top: 50%; right: 0; transform: translateY(-50%) translateX(-100%);"
              class="loading loading-spinner loading-xs absolute"
            ></span>
          </label>
          <div class="glossy-bg" data-autocomplete-container="true"></div>
        </div>
      </div>
      <form method="dialog" class="modal-backdrop">
        <button>close</button>
      </form>
    </Fragment>
  );
});
