import { addPeer } from "@gratico/bus";
import ShellSharedWorker from "../worker?worker&url";
import {
  bootViewportKernel,
  createViewportKernel,
  IUserSession,
  ILoggedinSession,
  IMasterKernelAPI,
  IBootParams,
} from "@gratico/kernel";
import { wrap } from "comlink";
import { RouterObject } from "alfama-router";

// function WorkaroundWorker(options: { name?: string }) {
//   console.log("i", import.meta.url);
//   const js = `
//   self.GRATICO_BASE_URL = "${window.location.protocol}//${
//     window.location.host
//   }";
//   import ${JSON.stringify(new URL(workerUrl, import.meta.url))}`;
//   const blob = new Blob([js], { type: "application/javascript" });
//   const objURL = URL.createObjectURL(blob);
//   const worker = new SharedWorker(objURL, {
//     type: "module",
//     name: options?.name,
//   });
//   worker.addEventListener("error", (e) => {
//     URL.revokeObjectURL(objURL);
//   });
//   return worker;
// }

export const bc = new BroadcastChannel("project");

export async function fetchSession(host: string): Promise<IUserSession> {
  const req = await fetch(`/~/api/v1/auth/session?host=${host}`);
  if (req.status !== 200) {
    throw new Error(req.status + "");
  }
  const session = await req.json();
  return session;
}

/** Connects viewport to a sharedworker that works as master.
 */
export async function connectToMaster(
  router: RouterObject,
  bootParams: IBootParams
) {
  //  console.log(ShellSharedWorker);
  const sharedworkerUnavailable = typeof SharedWorker === "undefined";
  const W = sharedworkerUnavailable ? Worker : SharedWorker;
  const worker = new W(ShellSharedWorker, {
    type: "module",
    name: window.location.host, //+ "@" + session.project.name,
  });
  worker.addEventListener("error", (e) => {
    console.log("error", e);
  });
  //  console.log("w", worker);
  const master = wrap(
    sharedworkerUnavailable
      ? (worker as any)
      : (worker as unknown as SharedWorker).port
  ) as unknown as IMasterKernelAPI;
  console.log("master", master);
  const viewport = await master.bootViewport(bootParams);
  //console.log("viewport", viewport);
  addEventListener("beforeunload", function () {
    master.shutdownViewport(viewport.id);
  });
  const kernel = createViewportKernel(master, viewport, router);

  addPeer(kernel.messageBus, "master", "local", { bc: kernel.localChannel });

  console.log("kernel", kernel);

  try {
    await bootViewportKernel(kernel);
  } catch (e) {
    console.error("Failed booting runtime", e);
  }

  return { master: master, viewport, kernel };
}
