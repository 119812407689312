/** @jsx h **/

import {} from "@gratico/ast";
import { IKernel } from "@gratico/kernel";
import {
  IActiveBuffer,
  IBuffer,
  IBuffers,
  IBuffersState,
  ILoggedinSession,
  IMasterKernelAPI,
} from "@gratico/sdk";
import { component, Each, h, reify, Signal, StoreCursor } from "alfama";
import { Link, ParentRouteContext, RouterContext } from "alfama-router";
import { basename } from "pathe";

///type A = ExtractElement<{ [id: string]: IWorkingCopy }>

export const Tabs = component<{
  session: ILoggedinSession;
  kernel: IKernel;
  master: IMasterKernelAPI;
  $activeRef: Signal<string>;
  $buffers: StoreCursor<IBuffersState>;
}>("Tabs", (props, { wire, signal, onMount, getContext, onUnmount }) => {
  const router = getContext(RouterContext).get();
  const routerHandler = () => {
    //console.log("route changed");
  };
  onMount(() => {
    router.addEventListener("popstate", routerHandler);
  });
  onUnmount(() => {
    router.removeEventListener("popstate", routerHandler);
  });
  return (
    <div
      style="width: 100%; overflow-x: auto; scrollbar-width: none;"
      class="flex"
    >
      <Each
        cursor={props.$buffers.list}
        renderItem={(cursor) => {
          const buffer = reify(cursor());
          return (
            <div
              style={wire(($): string => {
                const active = $(props.$buffers.activeBufferId);
                return active === buffer.id
                  ? "background: #ffffff08; border-color: dodgerblue;"
                  : "";
              })}
              class={"flex px-2 py-2 border-b-2 border-transparent"}
            >
              <div
                style="width: 20px"
                class={"flex items-center justify-center"}
                onClick={(e) => {}}
              >
                <img
                  data-fileicon="true"
                  style="width: 20px"
                  src={
                    buffer.icon ||
                    `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"/>
`
                  }
                />
              </div>
              <Link
                class="flex items-center justify-center pl-2 truncate text-sm"
                href={`/~/${buffer.type}/${buffer.projectId}/${buffer.ref}/~${buffer.path}`}
              >
                {basename(buffer.path)}
              </Link>
              {false && <i class="ri-asterisk text-sm"></i>}
            </div>
          );
        }}
      ></Each>
    </div>
  );
});

export const TabsIsland = component<{
  session: ILoggedinSession;
  kernel: IKernel;
  master: IMasterKernelAPI;
  $activeRef: Signal<string | undefined>;
  $buffers: StoreCursor<IBuffers & IActiveBuffer>;
}>("TabsIsland", (props, { signal, store, onMount, getContext, onUnmount }) => {
  return (
    <div
      style="height: 40px; z-index: 214748364; left: 80px; right: 80px;"
      class={
        "flex layered-bg absolute top-0 left-8 right-8 border rounded-r rounded-l border-t-0"
      }
    >
      <Tabs
        {...props}
        $activeRef={props.$activeRef as Signal<string>}
        $buffers={props.$buffers}
      />
    </div>
  );
});
