/** @jsx h **/

import { component, Signal, h } from "alfama";
import { BufferRoutePattern } from "../../constants";
import { RouterContext, extractParameters } from "alfama-router";
import { IBufferRouteParams } from "@gratico/sdk";
import { ChatInner } from "./Chat";

export const Chat = component<{ $expanded: Signal<boolean> }>(
  "Chat",
  (props, { signal, store, wire, onMount, onUnmount, getContext }) => {
    const router = getContext(RouterContext).get();
    const $activeRoute = signal<Partial<IBufferRouteParams> | undefined>(
      "route",
      undefined
    );
    const handler = () => {
      const params = extractParameters<Partial<IBufferRouteParams>>(
        BufferRoutePattern,
        router.location.pathname
      );
      $activeRoute.set(params);
    };
    onMount(() => {
      window.addEventListener("popstate", handler);
    });
    onUnmount(() => {
      window.removeEventListener("popstate", handler);
    });
    return <ChatInner {...props} />;
  }
);
