/** @jsx h **/

import { component, Fragment, h, VElement } from "alfama";
import s from "./SegmentedSelect.module.less";

export type SegmentedSelectItem<T = any> = {
  id: string;
  label: string;
  meta: T;
};
export type SegmentedSelectProps<T = any> = {
  items: SegmentedSelectItem<T>[];
  onChange: (item?: SegmentedSelectItem<T>) => void;
  renderItem: (item: SegmentedSelectItem<T>, i: number) => VElement;
  initialValue?: string;
  selectedClass?: string;
};

export const SegmentedSelect = component<SegmentedSelectProps>(
  "SegmentedSelect",
  (props, { signal, wire }) => {
    const $selected = signal<string | undefined>(
      "selected",
      props.initialValue
    );
    return (
      <Fragment>
        <div class={s.container}>
          {props.items.map((item, i) => {
            return (
              <div
                class={wire(($) => {
                  if ($selected.get($) === item.id) {
                    return `${s.item} ${s.selected} ${
                      props.selectedClass || ""
                    }`;
                  } else {
                    return s.item;
                  }
                })}
                data-active={wire(($) => {
                  if ($selected.get($) === item.id) {
                    return true;
                  } else {
                    return undefined;
                  }
                })}
                key={i}
                onClick={(e) => {
                  e.preventDefault();
                  if ($selected.get() === item.id) {
                    $selected.set(undefined);
                    props.onChange(undefined);
                  } else {
                    $selected.set(item.id);
                    props.onChange(item);
                  }
                }}
              >
                {props.renderItem(item, i)}
              </div>
            );
          })}
        </div>
        <select
          onChange={(e) => {
            const value = e.currentTarget.value;
            $selected.set(value);
          }}
          hidden={true}
        >
          {props.items.map((item, i) => {
            return (
              <option
                selected={wire(($) =>
                  $selected.get($) === item.id ? (true as any) : undefined
                )}
                value={item.id}
                key={i}
              >
                {item.label}
              </option>
            );
          })}
        </select>
      </Fragment>
    );
  }
);
