/** @jsx h **/

import {
  component,
  Fragment,
  getCursor,
  h,
  reify,
  StoreCursor,
  When,
} from "alfama";

import { IAlfamaProvider } from "@gratico/kernel";
import { BanyanProps, TreeNode } from "../../types";
import { IModeTypes, requestMasterToExpand } from "../../utils/index";
import { Tree } from "../Tree";
import { TreeNodeLabel } from "../TreeItemLabel";
import styles from "./TreeItem.module.less";

export { IModeTypes } from "../../utils/index";

export const TreeNodeItem = component<
  BanyanProps & {
    root?: boolean;
    cursor: () => StoreCursor<TreeNode>;
    treeProvider: IAlfamaProvider;
  }
>("TreeList", (props, { wire, getContext, onMount, signal }) => {
  const item = reify(props.cursor());
  const $expanded = signal("expanded", false);

  onMount(async () => {
    if (props.root) {
      await props.requestExpansion(props.cursor());
      $expanded.set(true);
    }
  });

  return (
    <div class={styles.treeList}>
      <When
        condition={($) => {
          return true;
        }}
        views={{
          true: () => {
            return (
              <Fragment>
                <TreeNodeLabel
                  {...props}
                  cursor={props.cursor}
                  $expanded={$expanded}
                />
                <When
                  condition={$expanded.get}
                  views={{
                    true: () => (
                      <Tree
                        {...props}
                        $expanded={$expanded}
                        root={false}
                      ></Tree>
                    ),
                    false: () => null,
                  }}
                ></When>
              </Fragment>
            );
          },
          false: () => {
            return null;
          },
        }}
      />
    </div>
  );
});
