/** @jsx h **/

import { component, Fragment, h } from "alfama";
import { SegmentedSelect } from "@gratico/uikit";
import pathe from "pathe";

const fileTypes = [
  {
    id: "board",
    label: "Board",
    meta: { icon: "ri-artboard-2-line", ext: "space" },
  },
  {
    id: "ts",
    label: "TS",
    meta: { icon: "devicon-typescript-plain", ext: "ts" },
  },
  {
    id: "tsx",
    label: "TSX",
    meta: { icon: "devicon-typescript-plain", ext: "tsx" },
  },
  { id: "directory", label: "Directory", meta: { icon: "ri-folder-line" } },
  { id: "file", label: "File", meta: { icon: "ri-file-line" } },
];
// <i class=""></i>

export type FilePropertiesProps = {
  onClose: Function;
  parentFolder: string;
  nodeName: string;
};
export const FileProperties = component<FilePropertiesProps>(
  "FileProperty",
  (props, { signal, wire }) => {
    const $name = signal("name", "");
    const $type = signal<string | undefined>("type", undefined);
    const $loading = signal("loading", false);

    return (
      <Fragment>
        <div
          style="border: 1px solid var(--component-border-color);  box-shadow: var(--component-box-shadow);"
          class="modal-box w-[100%] max-w-[1000px] layered-bg"
        >
          <form
            onSubmit={async () => {
              const resp = await fetch("/~/api/sessions", {
                method: "put",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({}),
              });
              if (resp.status === 200) {
                $loading.set(false);
              } else {
                $loading.set(false);
              }
            }}
            class={" "}
          >
            <div class={" "}>
              <i class="ri-box-3-line"></i> Component
            </div>
            <div class={" "}>
              <div class="form-control p-2">
                <label>Name</label>
                <input
                  class="input input-bordered input-sm "
                  value={wire($name.get)}
                  onInput={(e) => {
                    $name.set(e.currentTarget.value);
                  }}
                  type="text"
                  placeholder="name"
                />
              </div>
              <div class={"form-control p-2 "}>
                <label>Type</label>
                <SegmentedSelect
                  items={fileTypes}
                  renderItem={(item) => {
                    return (
                      <div class={" "}>
                        <div>
                          <i class={item.meta.icon}></i>
                        </div>
                        {item.label}
                      </div>
                    );
                  }}
                  onChange={(item) => {
                    if (item) {
                      $type.set(item.id);
                    } else {
                      $type.set(undefined);
                    }
                  }}
                />
              </div>
              <p>
                {wire(($) => {
                  const name = $name.get($);
                  if (!name || name.length == 0) {
                    return "please enter a name";
                  }
                  const type = $type.get($);
                  const typeEl = type && fileTypes.find((el) => el.id === type);
                  if (typeEl) {
                    return pathe.join(
                      props.parentFolder,
                      typeEl.meta.ext ? `${name}.${typeEl.meta.ext}` : name
                    );
                  }
                  return "please select a type";
                })}
              </p>
            </div>
            <div class=" ">
              <button type="submit" class="btn btn-primary btn-sm">
                <i class="ri-add-box-line"></i> save
              </button>
            </div>
          </form>
        </div>
        <form method="dialog" class="modal-backdrop">
          <button>close</button>
        </form>
      </Fragment>
    );
  }
);
