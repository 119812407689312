/** @jsx h **/

import {
  IActiveBuffer,
  IBuffers,
  IKernel,
  ILoggedinSession,
  IMasterKernelAPI,
} from "@gratico/kernel";
import { Fragment, Signal, StoreCursor, component, h } from "alfama";
import { Link } from "alfama-router";

import { ModalsAPIContext } from "../../providers";
//import { GitInfoMenu } from "../HeaderIsland/GitInfoMenu";

export const ActionsIsland = component<{
  session: ILoggedinSession;
  kernel: IKernel;
  master: IMasterKernelAPI;
  $activeRef: Signal<string>;
  $session: Signal<ILoggedinSession>;
  $buffers: StoreCursor<IBuffers & IActiveBuffer>;
}>("ActionsIsland", (props, { wire, getContext }) => {
  const modalsAPI = getContext(ModalsAPIContext);
  const { kernel } = props;
  const { emailHash, anon, avatar, name } = kernel.params.session.user;
  const avatarURL = avatar
    ? avatar
    : `https://gravatar.com/avatar/${emailHash}`;
  return (
    <Fragment>
      <div style="right: 0; top: 0;z-index: 2147483647;" class="fixed">
        <div class="dropdown dropdown-bottom dropdown-end">
          <Link
            href="#"
            tabIndex={0}
            class="px-1 py-1 text-2xl rounded flex justify-center items-center"
            style="background: var(--body-bg-color); color: var(--component-color); border: 1px solid var(--component-border-color); box-shadow: var(--component-box-shadow); border: 1px solid rgba(255, 255, 255, 0.08);"
          >
            <div class="avatar">
              <span class="w-5 rounded">
                {anon ? (
                  <i class="ri-user-line text-sm flex justify-center items-center"></i>
                ) : (
                  <img src={avatarURL} alt={name || "Anonymous"} />
                )}
              </span>
            </div>
            <span class="ri-arrow-drop-down-line"></span>
          </Link>
          <ul
            tabIndex={0}
            class="dropdown-content menu menu-sm rounded-box w-52 border h"
          >
            <li target="_blank">
              <a href={`/dashboard`}>
                <i class="ri-dashboard-line"></i> Settings
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
});
