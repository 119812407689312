/** @jsx h **/

import { ILoggedinSession } from "@gratico/sdk";
import { component, h, When } from "alfama";
import { LogoMenu } from "./LogoMenu";
import { getHubHost } from "./utils";

export const HeaderIsland = component<{ session: ILoggedinSession }>(
  "HeaderIsland",
  (props, { signal, onMount, getContext }) => {
    const hubHost = getHubHost();
    return (
      <div style="left: 5px; top: 5px; z-index: 2147483647; " class={"fixed "}>
        <LogoMenu {...props} />
        <When
          condition={($) => {
            return props.session.user.anon ? "anon" : "logged";
          }}
          views={{
            loading: () => null,
            anon: () => {
              return null;
              //              return (
              //                <a
              //                  target="_blank"
              //                  href={`${hubHost}/auth/request`}
              //                  class="ml-1 btn btn-primary"
              //                >
              //                  <i class="ri-fingerprint-line"></i>
              //                  <span style="padding: 0 0 0 5px;">{" signup "}</span>
              //                </a>
              //              );
            },
            logged: () => {
              return null;
            },
          }}
        ></When>
      </div>
    );
  }
);
