import { IBufferRouteParams, IBufferType } from "./types/objects";

export function getBufferIdFromRouterParams(params: {
  projectId: string;
  ref: string;
  path: string;
}): string {
  return `${params.projectId}:${params.ref}:${params.path}`;
}

export * from "./types/index";

export type TS = typeof import("typescript");
