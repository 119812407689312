/** @jsx h **/

import {
  IActiveBuffer,
  IBufferRouteParams,
  IDiagnostics,
  ILoggedinSession,
  IProjectChangedEventPaylod,
} from "@gratico/kernel";
import { Fragment, Signal, component, h, produce } from "alfama";
import { extractParameters, Route, RouterContext, Switch } from "alfama-router";

import { KernelConnection, KernelProvider } from "../../providers/index";

import hotkeys from "hotkeys-js";
import { HeaderIsland } from "../../components/HeaderIsland";
import { NavIsland } from "../../components/NavIsland";
import { TabsIsland } from "../../components/TabsIsland";
import { BuffersContext, ModalsAPIContext } from "../../providers/index";
import { setTitle } from "../../utils";
import { CommandBar } from "../CommandBar/index";
import { BootedLayoutInner } from "./BootedLayoutInner";
import { BufferRoute } from "./BufferRoute";
import { Home } from "./Home";
import { BufferRoutePattern } from "../../constants";
import { ActionsIsland } from "../ActionsIsland";
import { OverviewBar } from "../OverviewBar";
import { debounce } from "@gratico/stdlib";

hotkeys.filter = function (e) {
  var tag = (e.target || e.srcElement) as HTMLElement;
  //console.log("t", tag);
  const isEditable =
    tag &&
    (/^(INPUT|TEXTAREA|SELECT)$/.test((tag as HTMLElement).tagName) ||
      tag.isContentEditable);
  if (isEditable) {
    if (e.key === "Escape") {
      // tag.blur();
      return false;
    }
    // console.log("input", tag, e.code);
    return false;
  }
  return true;
};

export const BootedLayout = component<{
  $connection: Signal<KernelConnection>;
  $session: Signal<ILoggedinSession>;
}>(
  "BootedLayout",
  (
    props,
    { wire, setContext, getContext, onMount, onUnmount, signal, store }
  ) => {
    const { kernel, master } = props.$connection.get();
    const $buffers = getContext(BuffersContext);
    const modalsAPI = getContext(ModalsAPIContext);

    const $diagnostics = store<IDiagnostics>("diagnostics", {
      diagnostics: [],
    });

    // set up data
    const $activeRef = signal<string | undefined>("activeRef", undefined);
    const $chatExpanded = signal("expanded", false);
    const $activePanel = signal<string | undefined>("active", undefined);

    const $activeBuffer = signal<IActiveBuffer>("activeBuffer", {});
    const session = props.$session.get();

    // title
    setTitle(`${session.project.name}`);

    onMount(() => {
      hotkeys("ctrl+k, command+k, ctrl+shift+p, command+shift+p", function (e) {
        e.preventDefault();
        e.stopPropagation();
        try {
          modalsAPI.closeModal();
          modalsAPI.showModel(
            <CommandBar
              master={master}
              kernel={kernel}
              onClose={() => {
                modalsAPI.closeModal();
              }}
            />
          );
        } catch (e) {
          console.error(e);
        }
      });
      hotkeys("esc", (e) => {
        //console.log("esc");
        if ($chatExpanded.get()) return $chatExpanded.set(false);
        $activePanel.set(undefined);
        modalsAPI.closeModal();
      });
      hotkeys("space", (e) => {
        //console.log("space");
        $chatExpanded.set(!$chatExpanded.get());
        $activePanel.set("hydra");
      });
    });
    onUnmount(() => {
      hotkeys.unbind("ctrl+k, command+k");
      hotkeys.unbind("esc");
    });

    const _projectChangedHandler = async (data: IProjectChangedEventPaylod) => {
      console.log("projectChanged", data);
      const activeRoute = $activeRoute.get();
      if (data.projectId === activeRoute?.projectId) {
        produce($diagnostics, (s) => (s.loading = true));
        const diagnostics = await master.getLSPDiagnostics(
          data.projectId,
          data.ref
        );
        if (diagnostics) {
          console.log("diagnostics", diagnostics);
          produce($diagnostics, (s) => {
            s.loading = false;
            s.diagnostics = diagnostics;
          });
        } else {
          console.log("diagnostics", diagnostics);
          produce($diagnostics, (s) => {
            s.loading = false;
          });
        }
      }
    };

    const projectChangedHandler = debounce(_projectChangedHandler, 600);

    onMount(() => {
      kernel.pubsub.emitter.on("projectChanged", projectChangedHandler);
      //      projectChangedHandler({
      //        projectId: kernel.params.session.project.id,
      //        ref: "master",
      //        version: 0,
      //      });
    });

    onUnmount(() => {
      kernel.pubsub.emitter.off("projectChanged", projectChangedHandler);
    });

    const router = getContext(RouterContext).get();

    const $activeRoute = signal<Partial<IBufferRouteParams> | undefined>(
      "route",
      undefined
    );
    const routeHandler = () => {
      const params = extractParameters(
        BufferRoutePattern,
        router.location.pathname
      );
      $activeRoute.set(params);
    };
    onMount(() => {
      router.addEventListener("popstate", routeHandler);
      routeHandler();
    });
    onUnmount(() => {
      router.removeEventListener("popstate", routeHandler);
    });

    return (
      <KernelProvider master={master} kernel={kernel}>
        <Fragment>
          <BootedLayoutInner
            {...props}
            kernel={kernel}
            master={master}
            session={session}
            $activeRef={$activeRef as Signal<string>}
            $buffers={$buffers}
            $activeBuffer={$activeBuffer}
          />
          <Switch>
            <Route key="home" path="~" component={Home} />
            <Route
              key="file"
              path={BufferRoutePattern}
              component={BufferRoute}
            />
          </Switch>
          <TabsIsland
            {...props}
            $buffers={$buffers}
            session={session}
            kernel={kernel}
            master={master}
            $activeRef={$activeRef}
          />
          <NavIsland
            {...props}
            session={session}
            kernel={kernel}
            master={master}
            $activeRef={$activeRef}
          />
          <OverviewBar
            {...props}
            kernel={kernel}
            master={master}
            $expanded={$chatExpanded}
            $diagnostics={$diagnostics}
            $activePanel={$activePanel}
          />
          <HeaderIsland session={session} />
          <ActionsIsland
            {...props}
            $buffers={$buffers}
            session={session}
            kernel={kernel}
            master={master}
            $activeRef={$activeRef as Signal<string>}
          />
        </Fragment>
      </KernelProvider>
    );
  }
);
