/** @jsx h **/

import { IDiagnostics, IMasterKernelAPI, IViewportKernel } from "@gratico/sdk";
import { component, StoreCursor, h } from "alfama";

const commands = [
  { shortcut: "RET", description: "Jump to bookmark", color: "text-green-400" },
  {
    shortcut: "SPC",
    description: "Find file in project",
    color: "text-green-400",
  },
  { shortcut: "TAB", description: "+workspace", color: "text-green-400" },
  { shortcut: "*", description: "Resume last search" },
  { shortcut: "*", description: "Search for symbol in project" },
  { shortcut: ",", description: "Switch workspace buffer" },
  { shortcut: ".", description: "Find file" },
  { shortcut: "/", description: "Search project" },
  { shortcut: ":", description: "M-x" },
  { shortcut: ";", description: "Eval expression" },
  { shortcut: "<", description: "Switch buffer" },
  { shortcut: ">", description: "Switch to last buffer" },
  { shortcut: "a", description: "Actions" },
  { shortcut: "b", description: "+buffer", color: "text-green-400" },
  { shortcut: "c", description: "+code", color: "text-green-400" },
  { shortcut: "f", description: "+file", color: "text-green-400" },
  { shortcut: "g", description: "+git", color: "text-green-400" },
  { shortcut: "h", description: "+help", color: "text-green-400" },
  { shortcut: "i", description: "+insert", color: "text-green-400" },
  { shortcut: "m", description: "<localleader>", color: "text-green-400" },
  { shortcut: "n", description: "+notes", color: "text-green-400" },
  { shortcut: "o", description: "+open", color: "text-green-400" },
  { shortcut: "p", description: "+project", color: "text-green-400" },
  { shortcut: "q", description: "+quit/session", color: "text-green-400" },
  { shortcut: "s", description: "+search", color: "text-green-400" },
  { shortcut: "t", description: "+toggle", color: "text-green-400" },
  { shortcut: "u", description: "Universal argument" },
  { shortcut: "w", description: "+window", color: "text-green-400" },
  { shortcut: "x", description: "Pop up scratch buffer" },
  { shortcut: "X", description: "Org Capture" },
  { shortcut: "~", description: "Toggle last popup" },
];

export const Hydra = component<{
  kernel: IViewportKernel;
  $diagnostics: StoreCursor<IDiagnostics>;
  master: IMasterKernelAPI;
}>("Hydra", (props, utils) => {
  return (
    <div class=" text-white p-4 font-sans w-full">
      <div class="grid grid-cols-3 gap-x-8 gap-y-1">
        {commands.map((cmd, index) => (
          <CommandItem key={index} {...cmd} />
        ))}
      </div>
    </div>
  );
});

const CommandItem = ({
  shortcut,
  description,
  color = "text-blue-400",
}: any) => (
  <div class="flex justify-between items-center mb-1">
    <span class={`font-mono ${color} mr-2`}>{shortcut}</span>
    <span class="text-gray-300 text-sm">{description}</span>
  </div>
);
