import { ObjPathProxy, StoreCursor } from "alfama/state";
import { IFs } from "@gratico/fs";
import Emittery from "emittery";
import { INode } from "@gratico/ast";

export type GithubInstallation = {
  id: number;
  account: { login: string; id: number; avatar_url: string; url: string };
};

export type GithubRepository = {
  id: number;
  name: string;
  full_name: string;
  clone_url: string;
  owner: GithubOwner;
  topics: string[];
  description?: string;
  default_branch: string;
  size: number;
};
export type GithubOwner = {
  login: string;
  id: number;
  avatar_url: string;
  type: "User" | "Organization";
};

export type IRepoChangeType =
  | "MODIFIED"
  | "NEW"
  | "DELETED"
  | "RENAMED"
  | "COPIED"
  | "UPDATED_UNMERGED"
  | "UNTRACKED"
  | "IGNORED"
  | "TYPECHANGE";

export type IRepoChange = {
  path: string;
  type: IRepoChangeType;
};

export type IBranchChange = {
  path: string;
  type?: IRepoChangeType;
  isDirty?: true;
};
export type IBranchChanges = IBranchChange[];

type TS = typeof import("typescript");

import type { LanguageService, LanguageServiceHost, System } from "typescript";

export type EnvState = {
  files: { [path: string]: DocumentRegistryItem & { checksum: string } };
  pluginState: { [id: string]: any };
  projectVersion: number;
};

export type DocumentRegistryItem = {
  contents: string;
  version: string;
  checksum: string;
};

export interface FileIndex {
  imports: {
    importedName: string;
    moduleName: string;
    resolvedPath?: string;
  }[];
  nodes: INode[];
  locals: { name: string; kind: string; isExported: boolean }[];
  usageMap: Record<string, { importName: string; moduleName: string }[]>;
}

export type VirtualTypeScriptEnv = {
  ts: TS;
  fs: IFs;
  sys: System;
  store: EnvState;
  rootFiles: string[];
  fileIndexCache: Record<string, FileIndex>;
  languageService: LanguageService;
  languageServiceHost: LanguageServiceHost;
  addFile: (fileName: string) => void;
  updateFile: (fileName: string, content: string) => void;
  deleteFile: (fileName: string) => void;
  getSourceFile: (fileName: string) => string | undefined;
};

export enum MessageType {
  QUERY_STATE,
  APPLY_UPDATE,
  QUERY_AWARENESS,
  UPDATE_AWARENESS,
}

export type IRealtimeMessage<T = any> = {
  id: string;
  type: MessageType;
  data?: T;
  from?: string;
  to?: string;
  createdAt: number;
};

export interface IRealtimeProvider<Doc = any, Message = any, Awareness = any> {
  id: string;
  docId: string;
  type: string;
  doc: Doc;
  awareness: Awareness;
  getState: Function;
  receiveMessage: (msg: Message) => void;
  sendMessage: (msg: Message) => Promise<void>;
  queryState: Function;
  destroy: Function;
  applyUpdate: Function;
  emitter: Emittery;
  getText(): string;
}

export type IFolderMeta = {
  files: {
    [name: string]: IFileMeta;
  };
};

export type IFileIcon =
  | {
      type: "url";
      value: string;
    }
  | { type: "pack"; value: string; pack?: string };

export type IFileMeta = {
  name: string;
  description: string;
  tags: string[];
  icon?: IFileIcon;
  meta: Record<string, any>;
  timestamps: { created: number; updated: number };
};

export type IProject = {
  id: string;
  userId: string;
  slug?: string;
  name: string;
  providerId?: string | null;
  provider?: string | null;
};

export type IUser = {
  id: string;
  name: string;
  username?: string;
  anon?: boolean;
  avatar?: string;
  emailHash?: string;
};

export type IThread = {
  id: string;
  slug: string;
  name: string;
  userIds: string[];
  userId: string;
  meta: Record<string, any>;
};

export type IThreads = {
  list: IThread[];
};

export enum IBufferType {
  YJS = "yjs",
  ALFAMA = "alfama",
}

export type IFileBuferRouteParams = {
  type: IBufferType;
  projectId: string;
  ref: string;
  path: string;
};

export type IBufferRouteParams = IFileBuferRouteParams;

export interface IBufferBase {
  type: IBufferType;
  loaded?: true;
}

export interface IFileBuffer extends IBufferBase {
  id: string;
  icon: string;
  type: IBufferType;
  projectId: string;
  ref: string;
  path: string;
  createdAt: number;
  baseChecksum?: string;
  checksum?: string;
  loaded?: true;
}

export type IBuffer = IFileBuffer;

export type IBuffers = {
  list: IBuffer[];
};

export type IActiveBuffer = {
  activeBufferId?: string;
};

export type IBuffersState = IBuffers & IActiveBuffer;

export type ILoggedoutSession = {
  loggedIn: false;
};

export type IBaseSession = {
  loggedIn: boolean;
  project: IProject;
};

export type ILoggedinSession = {
  loggedIn: true;
  isMember: boolean;
  members: IUser[];
  project: IProject;
  user: IUser;
} & IBaseSession;

export type IUserSession = ILoggedinSession | ILoggedoutSession;

export type IBootParams = {
  session: ILoggedinSession;
};

export interface IViewport {
  id: string;
  params: IBootParams;
  masterId: string;
}

export type IRepoRef = {
  type: "branch";
  value: string;
};

export interface ICheckout<FS = IFs> {
  id: string;
  fs: FS;
  ref: IRepoRef;
  $tree: StoreCursor<TreeNode, TreeNode>;
  env: VirtualTypeScriptEnv;
}

export type TreeNode<T = any> = {
  name: string;
  path: string;
  type: string;
  icon: string;
  meta?: T;
  children: TreeNode[];
  isDirectory: boolean;
  state: { loaded?: boolean };
};

export type IKernelState = { [key: string]: any };

export interface IPeers {
  id: string;
}

export type IProjectChangedEventPaylod = {
  projectId: string;
  ref: string;
  version: number;
};

export type IDiagnostics = {
  loading?: boolean;
  diagnostics: any[];
};
