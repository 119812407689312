/** @jsx h **/

import { IModeTypes } from "@gratico/banyan";
import {
  ILoggedinSession,
  IMasterKernelAPI,
  IViewportKernel,
} from "@gratico/sdk";
import { component, Fragment, h, When } from "alfama";
import { CheckoutTree as CheckoutTreeLayout } from "./CheckoutTree";
import { SVGLoader } from "../../Layout/Loaders";
export { IModeTypes } from "@gratico/banyan";

const modesData: { [key: string]: { icon: string } } = {
  simple: { icon: "ri-node-tree" },
  file: { icon: "ri-code-line" },
  layers: { icon: "ri-stack-fill" },
};

export const CheckoutTree = component<{
  master: IMasterKernelAPI;
  kernel: IViewportKernel;
  session: ILoggedinSession;
  refName: string;
}>("CheckoutTree", (props, { signal, wire, onMount }) => {
  const { master, kernel, refName } = props;
  const $loaded = signal("loaded", false);
  onMount(async () => {
    const checkouts = await master.getCheckouts();
    //console.log("checkouts");
    checkouts.find((el) => el.id === refName) ||
      (await (async function () {
        await master.getOrCreateCheckout(props.session.project.id, { refName });
        //console.log("created checkout");
        $loaded.set(true);
      })());
  });
  const $mode = signal<(typeof IModeTypes)[number]>("mode", "simple");
  return (
    <Fragment>
      <When
        condition={$loaded.get}
        views={{
          true: () => (
            <div class={"w-full h-full relative text-1xl pl-3 pt-4"}>
              <CheckoutTreeLayout {...props} />
            </div>
          ),
          false: () => (
            <div>
              <SVGLoader class="w-full" />
            </div>
          ),
        }}
      ></When>
    </Fragment>
  );
});
