/** @jsx h **/

import {
  IActiveBuffer,
  IBuffers,
  IKernel,
  ILoggedinSession,
  IMasterKernelAPI,
} from "@gratico/kernel";
import { Fragment, Signal, StoreCursor, component, h } from "alfama";

import { KernelConnection } from "../../providers/index";

import { BuffersRenderer } from "./BuffersRenderer";

export const BootedLayoutInner = component<{
  session: ILoggedinSession;
  kernel: IKernel;
  master: IMasterKernelAPI;
  $activeRef: Signal<string>;
  $connection: Signal<KernelConnection>;
  $session: Signal<ILoggedinSession>;
  $buffers: StoreCursor<IBuffers>;
  $activeBuffer: Signal<IActiveBuffer>;
}>(
  "BootedLayoutInner",
  (props, { signal, store, onMount, getContext, onUnmount }) => {
    const $loaded = signal<true | undefined | Error>("$loaded", true);
    const { $buffers, kernel } = props;
    const refName = props.$activeRef.get();
    return (
      <Fragment>
        <BuffersRenderer {...props} session={props.$session.get()} />
      </Fragment>
    );
  }
);
