/** @jsx h **/

import { component, h } from "alfama";
import { animate, stagger, inView, spring, scroll } from "motion";

const features = [
  {
    title: "Add Symbols to the Whiteboard",
    description:
      "Instantly import symbols from your Git repo to visually document and structure your ideas.",
    icon: "ri-artboard-line",
  },
  {
    title: "Real-Time Coding and Execution",
    description:
      "Test your code as you type using the built-in REPL. Get immediate feedback and visualize runtime values next to your code.",
    icon: "ri-braces-line",
  },
  {
    title: "Collaborate with Ease",
    description:
      "Use the whiteboard to map out logic, document decisions, or plan your architecture. Share it with your team for faster collaboration.",
    icon: "ri-sparkling-line",
  },
  {
    title: "Streamline Your Workflow",
    description:
      "The IDE comes pre-configured with Git, letting you push changes, track commits, and collaborate without leaving the browser",
    icon: "ri-git-branch-line",
  },
];

export const Welcome = component("Welcome", (props, { onMount }) => {
  onMount(() => {});

  const startAnimation = (container: HTMLElement) => {
    inView(container, (info) => {
      animate(
        container.querySelectorAll(".animatedItem"),
        { opacity: 1, transform: "translate3d(0,0,0)" },
        {
          delay: stagger(0.07),
          easing: spring({ stiffness: 95, damping: 0.1, mass: 20 }),
          //glide({ power: 2, decay: 0.8, velocity: 0.9 }),
        }
      );
    });
  };

  return (
    <main
      ref={(el: HTMLElement) => startAnimation(el)}
      class="container mx-auto px-4 py-6 border rounded glossy-bg"
    >
      <h1
        style="line-height: 1.3; transform: translate3d(0,0px,0); will-change: opacity transform; font-family: var(--tagline-font-family);"
        class="text-5xl font-normal mb-2 animatedItem pb-6"
      >
        <span class=" logo ">
          <i class="ri-shining-fill"></i> gratico
        </span>{" "}
        has everything you need to do your best work.
      </h1>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
        {features.map((f) => {
          return (
            <div
              style="transform: translate3d(0,0px,0); will-change: opacity transform;"
              class="animatedItem hover:border-dashed py-2 px-2 pr-1 border-solid flex opacity-0"
              key={f.title}
            >
              <div class="flex"></div>
              <div class="flex-1  ">
                <h3 class="flex logo-gradient text-3xl font-normal mb-1 items-center ">
                  <div class=" text-4xl mb-0 mr-2">
                    <i class={f.icon}></i>
                  </div>
                  {f.title}
                </h3>
                <p class="py-3 my-0 text-1xl">{f.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </main>
  );
});
