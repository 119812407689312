/** @jsx h **/

import {
  component,
  Component,
  Each,
  Fragment,
  h,
  Portal,
  reify,
  Signal,
  When,
} from "alfama";

import {
  IApplication,
  IApplicationProps,
  IBuffer,
  IBufferType,
  ILoggedinSession,
} from "@gratico/sdk";

import { BuffersContext, KernelConnection } from "../../providers/index";
import imageURL from "../../images/escher/circle-limit.svg?url";
import { SVGLoader } from "../../components/Layout/Loaders";

import { createCheckoutRuntimeViewport } from "@gratico/kernel";
import { RouterContext, RouterObject } from "alfama-router";
import hotkeys from "hotkeys-js";
import pathe from "pathe";
export type BufferProps = {
  $connection: Signal<KernelConnection>;
  session: ILoggedinSession;
};

export async function fetchApplication(buffer: IBuffer) {
  //console.log("buffer type", buffer.type, buffer);
  if (buffer.type === IBufferType.YJS) {
    const ext = pathe.extname(buffer.path);
    if ([".tsx"].includes(ext)) {
      const mod = await import(
        "../../../../../packages/platform/apps/whiteboard/src/entrypoints/yjs/index"
      );
      return mod.application;
    } else {
      const mod = await import(
        "../../../../../packages/platform/apps/codemirror/src/index"
      );
      return mod.application;
    }
  } else {
    const mod = await import(
      "../../../../../packages/platform/apps/whiteboard/src/entrypoints/alfama/index"
    );
    return mod.application;
  }
}

export const renderApplication = async (
  container: HTMLElement,
  router: RouterObject,
  buffer: IBuffer,
  props: BufferProps
) => {
  const { kernel, master } = props.$connection.get();
  const app = await fetchApplication(buffer);
  //console.log("app", Application);
  const appProps = {
    session: props.session,
    kernel,
    master,
    buffer,
  };
  app.render(appProps, container);
};

export const ApplicationRenderer = component<
  BufferProps & {
    $buffer: IBuffer;
  }
>(
  "BufferRenderer",
  (props, { wire, setContext, getContext, onMount, onUnmount, signal }) => {
    const router = getContext(RouterContext).get();
    const $buffers = getContext(BuffersContext);
    onMount(() => {
      wire(($) => {
        const activeBufferId = $($buffers).activeBufferId;
        if (activeBufferId) {
          hotkeys.setScope(activeBufferId);
        } else {
          hotkeys.setScope("");
        }
      }).run();
    });
    const $container = signal<
      undefined | { container: HTMLElement; Application: IApplication }
    >("container", undefined); //

    // this renders component via portal of via render if non-alfama app
    return (
      <div
        style={wire(($): string => {
          const buffer = $(props.$buffer);
          let baseCSS = `position: absolute;width: 100%;height: 100%; z-index: 1000;`;
          const activeBufferId = $($buffers).activeBufferId;
          const isActive = buffer.id === activeBufferId;
          return baseCSS + (isActive ? `` : `display: none;`);
        })}
        ref={async (el: HTMLElement) => {
          const buffer = reify(props.$buffer);
          const Application = await fetchApplication(buffer);
          $container.set({ container: el, Application });
          if (!Application.component)
            renderApplication(el, router, reify(props.$buffer), props);
        }}
      >
        <When
          condition={($) => {
            const app = $container.get($);
            return !!app && !!app.Application.component;
          }}
          views={{
            true: () => {
              const app = $container.get() as {
                container: HTMLElement;
                Application: IApplication;
              };
              if (app && app.Application.component) {
                const { kernel, master } = props.$connection.get();
                const buffer = reify(props.$buffer);
                const C: Component<IApplicationProps> =
                  app.Application.component;
                return (
                  <Portal>
                    <C
                      session={props.session}
                      kernel={kernel}
                      master={master}
                      buffer={buffer}
                    />
                  </Portal>
                );
              } else {
                return null;
              }
            },
            false: () => {
              return null;
            },
          }}
        />
      </div>
    );
  }
);

export const BuffersRendererInner = component<
  BufferProps & {
    $buffer: IBuffer;
  }
>("BuffersRendererInner", (props, { signal, onMount }) => {
  const $loaded = signal("loaded", false);
  const { kernel, master } = props.$connection.get();
  onMount(async () => {
    const bufferParams = JSON.parse(JSON.stringify(reify(props.$buffer)));
    const openBuffer =
      (await master.getOpenBuffer(bufferParams)) ||
      (await master.openBuffer(bufferParams));
    await createCheckoutRuntimeViewport(
      kernel,
      bufferParams.projectId,
      bufferParams.ref
    );
    $loaded.set(true);
  });
  return (
    <When
      condition={$loaded.get}
      views={{
        true: () => <ApplicationRenderer {...props} />,
        false: () => (
          <div class="h-screen w-screen flex flex-col justify-center items-center">
            <img class="border rounded glossy-bg hidden" src={imageURL} />
            <div style=" margin: 10px auto;">
              <SVGLoader />
            </div>
            <span style="font-size: 24px;" class="boot-message-log">
              loading
            </span>
          </div>
        ),
      }}
    />
  );
});

export const BuffersRenderer = component<BufferProps>(
  "BuffersRenderer",
  (props, { wire, setContext, getContext, onMount, onUnmount }) => {
    const $buffers = getContext(BuffersContext);
    return (
      <Each
        cursor={$buffers.list}
        renderItem={(cursor) => {
          const buffer = reify(cursor());
          return (
            <Fragment key={buffer.id}>
              <BuffersRendererInner
                {...props}
                session={props.session}
                $buffer={cursor()}
              />
            </Fragment>
          );
        }}
      />
    );
  }
);
