import { IBaseKernel, TreeNode } from "@gratico/sdk";
import { basename, extname } from "pathe";
import { queue } from "@gratico/stdlib";

export const _getIconDefs = async (kernel: IBaseKernel) => {
  const iconDefsURL =
    "https://cdn.jsdelivr.net/npm/vscode-symbols@0.0.18/src/symbol-icon-theme.json";
  const iconDefs =
    kernel.cacheMap.get(iconDefsURL) ||
    (await (async function () {
      const req = await fetch(iconDefsURL);
      const json = await req.json();
      kernel.cacheMap.set(iconDefsURL, json);
      return json;
    })());
  return iconDefs;
};
const getIconDefs = queue(_getIconDefs, 1)();

export const getFileIcon = async (
  kernel: IBaseKernel,
  item: { isDirectory?: boolean; path: string },
  icons: Record<string, string> = {}
) => {
  const iconDefs = await getIconDefs(kernel);
  const fileName = basename(item.path);
  const ext = extname(fileName).slice(1);
  //  console.log("ext", ext);
  const icon = (() => {
    if (ext === "space") {
      return { iconPath: icons.whiteboard || "./icons/files/keystatic.png" };
    } else if (item.isDirectory) {
      const iconName = iconDefs.folderNames[fileName] || "folder";
      return iconDefs.iconDefinitions[iconName];
    } else {
      const iconName =
        ["package.json", "package-lock.json"].indexOf(fileName) > -1
          ? "npm"
          : ext == "tsx"
          ? "ts"
          : iconDefs.fileNames[fileName] ||
            iconDefs.fileExtensions[ext] ||
            "text";
      return iconDefs.iconDefinitions[iconName];
    }
  })();
  if (!icon || !icon.iconPath) {
    console.debug("missing icon", fileName, ext, icon);
    return "https://cdn.jsdelivr.net/npm/vscode-symbols@0.0.18/preview/files/text.png";
  }
  return icon.iconPath.match(/^https/)
    ? icon.iconPath
    : icon.iconPath
        .replace(
          "./icons",
          "https://cdn.jsdelivr.net/npm/vscode-symbols@0.0.18/preview"
        )
        .replace(".svg", ".png");
};
