/** @jsx h **/

import { ILoggedinSession } from "@gratico/sdk";
import { component, Fragment, h, When } from "alfama";
import { Link } from "alfama-router";
import { getHubHost } from "../utils";

export const LogoMenu = component<{ session: ILoggedinSession }>(
  "LogoMenu",
  (props, { signal, getContext }) => {
    const session = props.session;
    const hubHost = getHubHost();
    return (
      <div class="dropdown dropdown-hover">
        <Link
          href="/~"
          tabIndex={0}
          class="px-1 py-1 text-2xl rounded"
          style="background: var(--body-bg-color); color: var(--component-color); border: 1px solid var(--component-border-color); box-shadow: var(--component-box-shadow); border: 1px solid rgba(255, 255, 255, 0.08);"
        >
          <i class="logo ri-shining-fill"></i>
          <span class="ri-arrow-drop-down-line"></span>
        </Link>
        <When
          condition={($) => {
            return !session
              ? "loading"
              : (session.user as any).anon
              ? "anon"
              : "logged";
          }}
          views={{
            loading: () => null,

            logged: () => {
              return (
                <Fragment>
                  <ul
                    tabIndex={0}
                    class="dropdown-content menu menu-sm rounded-box w-52 border h"
                  >
                    <li target="_blank">
                      <a href={`${hubHost}/dashboard`}>
                        <i class="ri-dashboard-line"></i> Back to dashboard
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href={`${hubHost}/projects/new`}>
                        <i class="ri-add-circle-line"></i> New Project
                      </a>
                    </li>
                  </ul>
                </Fragment>
              );
            },
            anon: () => {
              return (
                <Fragment>
                  <ul
                    tabIndex={0}
                    class="dropdown-content menu menu-sm rounded-box w-52 border h"
                  >
                    <li key="back" target="_blank">
                      <a href={`${hubHost}`}>
                        <i class="ri-dashboard-line"></i> Back to hub
                      </a>
                    </li>
                    <li key="login" target="_blank">
                      <a href={`${hubHost}/auth/request`}>
                        <i class="ri-fingerprint-line"></i> Login
                      </a>
                    </li>
                  </ul>
                </Fragment>
              );
            },
          }}
        ></When>{" "}
      </div>
    );
  }
);
