import { IBaseKernel, IBufferRouteParams } from "@gratico/sdk";
import { extractParameters } from "alfama-router";
export const BufferRoutePattern = `~/:type/:projectId/:ref*/~/:path*`;

export function getCurrentProjectParameters(
  kernel: IBaseKernel,
  location: Location
) {
  const params = extractParameters<Partial<IBufferRouteParams>>(
    BufferRoutePattern,
    location.pathname
  );
  if (params?.projectId) {
    return { projectId: params.projectId, ref: params.ref || "master" };
  } else {
    return { projectId: kernel.params.session.project.id, ref: "master" };
  }
}
