import { ComponentUtils, Signal } from "alfama";

export const useSliding = (
  utils: ComponentUtils,
  handler: ($expanded: Signal<boolean>, e: MouseEvent) => void,
  expandedValue = 0,
  restValue = 90
) => {
  const $running = utils.signal("running", false);
  const $translateX = utils.signal("translateX", restValue);
  const $expanded = utils.signal("expanded", false);
  utils.onMount(() => {
    utils
      .wire(($, { previousValue }) => {
        const expanded = $expanded.get($);
        //console.log({ expanded, previousValue });
        //        if (expanded === previousValue) return expanded;
        //        if (animation) animation.stop();
        $translateX.set(expanded ? expandedValue : restValue);
        return expanded;
      })
      .run();
  });

  utils.onUnmount(() => {
    document.removeEventListener("mousemove", handler.bind(null, $expanded));
  });
  utils.onMount(() => {
    document.addEventListener("mousemove", handler.bind(null, $expanded));
  });
  handler($expanded, {
    clientX: 0,
    clientY: 0,
  } as any);
  return { $translateX, $expanded, $running };
};
