import { createBus } from "@gratico/bus";
import {
  IKernelState,
  IMasterKernelAPI,
  IViewport,
  IViewportKernel,
} from "@gratico/sdk";
import { RouterObject } from "alfama-router";
import { createStore } from "alfama/state";
import { getPreviewHTML } from "../runtime/utils/preview/index";
import { getSandboxHTML } from "../runtime/utils/sandbox/index";
import { setupGossipChannel, setupLocalChannel } from "./boot/index";
import { importLibrary } from "./utils";

export const LOCALSTORAGE_KEY = "userConfig";
export function createViewportKernel(
  master: IMasterKernelAPI,
  viewport: IViewport,
  router: RouterObject
) {
  const id = "v@" + viewport.id;
  const messageBus = createBus({});
  const eventBus = null;
  const localChannel = new BroadcastChannel("project");
  const pubsub = setupGossipChannel(id, localChannel, {
    process: true,
    reply: true,
    request: true,
    publish: true,
    receive: true,
    ack: true,
  });

  const userConfig = new Map(
    JSON.parse(localStorage[LOCALSTORAGE_KEY] || "[]")
  );

  const kernel: IViewportKernel = {
    id,
    importLibrary,
    destroy: () => {
      localChannel.removeEventListener("message", pubsub.handler);
    },
    router,
    pubsub,
    messageBus,
    eventBus,
    cacheMap: new Map(),
    masterId: viewport.masterId,
    master,
    booted: false,
    params: viewport.params,
    runtimes: new Map(),
    localChannel,
    state: createStore<IKernelState>({}),
    buffers: [],
    userConfig: {
      set(key, value) {
        userConfig.set(key, value);
        localStorage[LOCALSTORAGE_KEY] = JSON.stringify(
          Array.from(userConfig.entries())
        );
      },
      get(key) {
        return userConfig.get(key);
      },
    },
  };

  kernel.pubsub.reply(
    `viewport:getPreviewHTML:${kernel.id}`,
    async (params: { projectId: string; ref: string }) => {
      return await getPreviewHTML(kernel, params);
    }
  );

  kernel.pubsub.reply(
    `viewport:getSandboxHTML:${kernel.id}`,
    async (params: { projectId: string; ref: string }) => {
      return await getSandboxHTML(kernel, params);
    }
  );

  return kernel;
}

export async function bootViewportKernel(kernel: IViewportKernel) {
  const localChannel = setupLocalChannel(kernel);
  kernel.localChannel = localChannel;
  kernel.booted = true;
}
